import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Sigma Motors ACT"
          subTitle="Personal and reliable. With 15 plus years of experience servicing and repairing vehicles."
        />
        <BasicTextModule
          title="Your local mechanic"
          content="Vehicle servicing and repairs for all makes and models includes European cars starting at $200."
          link="/services"
          linkText="View Services"
        />
        <Features
          title="Services offered by Sigma Motors ACT."
          introduction="At Sigma Motors ACT I offer a large range of mechanical services from regular logbook servicing to many others."
        />
        <LatestPosts
          title="Reviews"
          introduction="Reviews from Sigma Motors ACT customers."
        />
      </Layout>
    </>
  )
}

export default Index
